
$main-bg-color: #012;
$box-bg-color: rgba(255, 255, 255, 0.05);
$def-text-color: #ddd;
$link-color: #1e90ff;
$content-width: 720px;
$content-limit-width: 768px;
$header-height: 72px;
$footer-height: 56px;
$band-color: rgba(64, 180, 180, 0.15);
$content-menu-height: 48px;
$content-menu-bg-color: rgba(200, 200, 255, 0.15);
$menu-item-hover-color: rgba(255, 255, 255, 0.1);
$menu-item-border-color: rgba(255, 255, 255, 0.05);

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-size: 1.0em;
    font-family: "Noto Sans JP", serif;
    color: $def-text-color;
    line-height: 1.5em;
}

body {
    background-color: $main-bg-color;
    padding: 0;
    margin: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

a {
    color: #1e90ff;

    &:hover {
        color: #3fb2ff;
    }

    &:active {
        color: orangered;
    }

    &:visited {
        color: deepskyblue;    
    }
}


@media screen and (max-width: $content-limit-width) {
    body {
        align-items: stretch;
    }

    .cWidth {
        max-width: none;
    }

    .menuWidth {
        width: 100%;
    }
}

@media screen and (min-width: $content-width) {
    body {
        align-items: center;
    }

    .cWidth {
        max-width: $content-width;
    }

    .menuWidth {
        width: $content-width;
    }
}


.contentArea {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    align-items: center;
}


.cbox {
    @extend .cWidth;
    margin: 0 auto;
    background-color: $box-bg-color;
    padding: 10px 20px;
}

main.cbox {
    padding: 20px;
}

header > div.cbox {
    display: flex;
    height: $header-height;
    background-color: rgba(255, 255, 255, 0.2);
    align-items: center;
}

footer > div.cbox {
    display: flex;
    height: $footer-height;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.1);
}

header {
    width: 100%;
    height: $header-height;
}

.contentMenu {
    @extend .cWidth, .menuWidth;
    display: flex;
    margin: 0 auto;
    background-color: $content-menu-bg-color;
    padding: 0;
    height: $content-menu-height;
}

.contentMenu > .menuItem {
    display: flex;
    align-items: center;
    padding: 0 16px;
    text-decoration: none;
    color: $def-text-color;
    cursor: pointer;
    gap: 10px;
    height: $content-menu-height;

    &:hover {
        background-color: $menu-item-hover-color;
    }

    &:not(:first-child) {
        border-left: 1px solid $menu-item-border-color;
    }
}


main {
    flex: 1;
}

footer {
    width: 100%;
    height: $footer-height;
    margin: auto auto 0 auto;
}

.headerContent {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.headerContent > .left {
    flex-grow: 1;
}
.headerContent > .right {
    display: flex;

    a {
        margin-top: auto;

        > img {
            height: 24px;
            vertical-align: bottom;
        }
    }
}


img.logo {
    height: 32px;
}



.content {
    font-size: 14px;

    > h1 {
        font-size: 24px;
    }

    > h2 {
        font-size: 18px;
    }

    > p {
        padding: 20px;
    }

     > ul {
        padding-left: 48px;
     }
}

.app {
    display: table;

    .row {
        display: table-row;
    }

    div.icon {
        display: table-cell;
        vertical-align: top;
        width: 64px;

        img {
            height: 48px;
        }
    }

    div.info {
        display: table-cell;
        vertical-align: top;

        h1 {
            font-size: 1.5em;
        }

        div {
            padding: 10px;
        }

        div.badges {
            img {
                height: 32px;           
            }

            a {
                &:not(:first-child) {
                    margin-left: 10px;
                }
            }
        }
    }


    &:not(:first-child) {
        margin-top: 32px;
    }
}
